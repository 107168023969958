
import { Component, Vue } from 'vue-property-decorator'
import { KeepAlive } from '@/utils/decorators'
@Component({
  components: {}
})
@KeepAlive
export default class Manage extends Vue {
    private page = 1
    private size = 10
    private total = 0
    private loading = false
    private tableData = []

    private searchForm = {
      materialTypeName: '', // 物料名称
      materialTypeId: '', // 物料分类
      state: '' // 状态1启用2禁用
    }

    private dialogVisible1 = false

    private dialogVisible2 = false

    private warehouseData = [] as any
    private materialTypeData = []
    private materialTypeForm = {
      materialTypeId: '',
      materialTypeName: '',
      state: ''
    }

    get projectList () {
      return this.$store.state.projectList
    }

    created () {
      this.loadData()
      this.loadMaterialType()
    }

    loadData () {
      this.loading = true
      this.$axios.get(this.$apis.material.selectYhMaterialTypeByPage, {
        page: this.page,
        size: this.size,
        ...this.searchForm
      }).then(res => {
        this.tableData = res.list
        this.total = res.total
      }).finally(() => {
        this.loading = false
      })
    }

    loadMaterialType () {
      this.$axios.get(this.$apis.material.selectYhMaterialTypeByPage).then(res => {
        this.materialTypeData = res.list
      })
    }

    onSearch () {
      this.page = 1
      this.loadData()
    }

    onStatus (materialTypeId: any, status: string) {
      const text = status === '1' ? '禁用' : '启用'
      this.$confirm(`确认${text}吗？`, '提示').then(() => {
        this.$axios.post(this.$apis.material.updateYhMaterialTypeState, {
          materialTypeId
        }).then(() => {
          this.$message.success('操作成功!')
          this.loadData()
        })
      })
    }

    onEdit (row: any) {
      this.$router.push({
        path: '/property/materialmanage/edit',
        query: {
          materialTypeId: row.materialTypeId,
          materialTypeName: row.materialTypeName,
          materialTypeCode: row.materialTypeCode,
          parentMaterialTypeId: row.parentMaterialTypeId,
          state: row.state
        }
      })
    }

    deleteRow (materialTypeId: string) {
      this.$confirm('确认删除该物料分类吗？', '提示').then(() => {
        this.$axios.post(this.$apis.material.deleteDataYhMaterialType, {
          materialTypeId
        }).then(() => {
          this.$message.success('删除成功!')
          this.loadData()
        })
      })
    }

    showWarehouse () {
      this.dialogVisible2 = true
      this.loadWarehouseData()
    }

    loadWarehouseData () {
      this.$axios.get(this.$apis.material.selectYhWarehouseByList).then(res => {
        this.warehouseData = res.list.map((item: any) => {
          item.isEdit = false
          return item
        })
      })
    }

    addWarehouse () {
      this.warehouseData.push({
        isEdit: true,
        projectId: '', // 项目ID
        projectName: '',
        warehouseName: '', // 仓库名称
        warehouseLiable: '', // 责任人
        warehouseMobile: '', // 联系方式
        warehouseAddress: '' // 仓库地址
      })
    }

    saveWarehouse (data: any) {
      const item = JSON.parse(JSON.stringify(data)) as any
      delete item.isEdit
      if (!item.projectId) {
        this.$message({ type: 'warning', message: '请选择项目' })
        return
      }
      if (!item.warehouseName) {
        this.$message({ type: 'warning', message: '请输入仓库名称' })
        return
      }
      if (item.warehouseMobile && !/^[0-9]*$/.test(item.warehouseMobile)) {
        this.$message({ type: 'warning', message: '联系方式为数字' })
        return
      }
      let url = this.$apis.material.insertYhWarehouse
      if (item.warehouseId) {
        url = this.$apis.material.updateYhWarehouse
      }
      this.$axios.post(url, item).then(() => {
        this.loadWarehouseData()
      })
    }

    delWarehouse (warehouseId: string, index: number) {
      if (warehouseId) {
        this.$axios.post(this.$apis.material.deleteYhWarehouse, {
          warehouseId
        }).then(() => {
          this.$message.success('删除成功!')
          this.loadWarehouseData()
        })
      } else {
        this.warehouseData.splice(index, 1)
      }
    }
}
